<!-- 首页 -->
<template>
  <div class="Home">
    <div class="layout">
      <Layout style="height: 100%">

        <!-- left -->
        <Sider breakpoint="md" collapsible :collapsed-width="78" v-model="isCollapsed">
          <Menu :active-name="title_name" theme="light" width="auto" :class="menuitemClasses" @on-select="get_name">
            <div class="title">黄埔人才网</div>
            <MenuItem :name="item.name" v-for="(item,index) in Menu_arr" :key="index" :to="item.Menu_name">

            <Icon :custom="item.icon" size="20" />
            <span>{{item.name}}</span>
            </MenuItem>

          </Menu>
          <div slot="trigger"></div>
        </Sider>

        <!-- right -->
        <Layout>
          <Header class="layout-header-bar">
            <div class="top">
              <p>{{title_name}}</p>
              <div>
                <p>{{firm_data.name}}</p>
                <img style="margin:0 20px" src="@/assets/images/message.png" alt="" @click="go_message">
                <img class="logo" :src="firm_data.logo?img_url(firm_data.logo):errorImg01" @click="go_person">
                <!-- <img class="logo" src="./asd.png" alt="123" @click="go_person"> -->

                <Button type="warning" @click="out">退出</Button>
              </div>
            </div>
          </Header>
          <Content :style="{padding:'15px'
          }">
            <keep-alive>
              <router-view v-if="$route.meta.keepAlive" :firm_data="firm_data" @setTitle="setTitle" @update="()=>{this.get_firm_data()}" />
            </keep-alive>

            <router-view v-if="!$route.meta.keepAlive" :firm_data="firm_data" @setTitle="setTitle" @update="()=>{this.get_firm_data()}"></router-view>

          </Content>
        </Layout>

      </Layout>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Home',
  data() {
    return {
      title_name: "总控制台",
      Menu_arr: [
        {
          name: '总控制台',
          Menu_name: 'Console',
          icon: "iconfont icon-a-1"
        },
        {
          name: '职位管理',
          Menu_name: 'job_manage',
          icon: "iconfont icon-a-2"
        },
        // {
        //   name: '简历管理',
        //   Menu_name: 'resume_manage',
        //   icon: "iconfont icon-a-3"
        // },
        {
          name: '简历管理',
          Menu_name: 'Management',
          icon: "iconfont icon-a-3"
        },
        {
          name: '面试管理',
          Menu_name: 'interview_manage',
          icon: "iconfont icon-a-4"
        },
        {
          name: '人才列表',
          Menu_name: 'talents_list',
          icon: "iconfont icon-a-5"
        },
        {
          name: '人才管理',
          Menu_name: 'talents_manage',
          icon: "iconfont icon-a-6"
        },
        // {
        //   name: '线下招聘会',
        //   Menu_name: 'job_fair',
        //   icon: "iconfont icon-a-7"
        // },
        {
          name: '线下招聘会',
          Menu_name: 'job_fair_new',
          icon: "iconfont icon-a-7"
        },
        {
          name: '个人中心',
          Menu_name: 'person',
          icon: "iconfont icon-a-8"
        },

        {
          name: '企业资料',
          Menu_name: 'firm_data',
          icon: "iconfont icon-a-9"
        },
        {
          name: '我的消息',
          Menu_name: 'my_message',
          icon: "iconfont icon-a-10"
        },
        {
          name: '联系客服',
          Menu_name: 'kefu',
          icon: "iconfont icon-a-11"
        },

      ],
      isCollapsed: false,
      //企业详情
      firm_data: {},
    }
  },
  methods: {
    CheckSocialCreditCode(Code) {
      var patrn = /^[0-9A-Z]+$/;
      //18位校验及大写校验  
      if ((Code.length != 18) || (patrn.test(Code) == false)) {
        return false;
      } else {
        var Ancode; //统一社会信用代码的每一个值  
        var Ancodevalue; //统一社会信用代码每一个值的权重   
        var total = 0;
        var weightedfactors = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28]; //加权因子   
        var str = '0123456789ABCDEFGHJKLMNPQRTUWXY';
        //不用I、O、S、V、Z   
        for (var i = 0; i < Code.length - 1; i++) {
          Ancode = Code.substring(i, i + 1);
          Ancodevalue = str.indexOf(Ancode);
          total = total + Ancodevalue * weightedfactors[i];
          //权重与加权因子相乘之和   
        }
        var logiccheckcode = 31 - total % 31;
        if (logiccheckcode == 31) {
          logiccheckcode = 0;
        }
        var Str = "0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,T,U,W,X,Y";
        var Array_Str = Str.split(',');
        logiccheckcode = Array_Str[logiccheckcode];
        var checkcode = Code.substring(17, 18);
        if (logiccheckcode != checkcode) {
          return false;
        }
        return true;
      }
    },
    setTitle(name) {
      console.log(name);
      this.title_name = name
    },
    get_name(name) {
      console.log(name);
      this.title_name = name
      sessionStorage.setItem("Menu", name)
    },
    //我的消息
    go_message() {
      this.$router.push({ name: 'my_message' })
      this.setTitle('我的消息')
    },
    //个人中心
    go_person() {
      this.$router.push({ name: 'person' })
      this.setTitle('个人中心')
    },
    out() {
      this.$Modal.confirm({
        title: "确定退出当前账号？",
        onOk: () => {
          localStorage.removeItem("firm_mobile")
          localStorage.removeItem("firm_token")
          localStorage.removeItem("firm_enterpriseId")
          this.$router.push('/')
        },
        closable: true
      })


    },
    //封装获取企业资料
    get_firm_data() {
      this.app('enterpriseOperators', 'getEnterprise', {
        enterpriseId: localStorage.getItem('firm_enterpriseId')
      }).then(res => {
        console.log("企业详情:",res);
        this.firm_data = res.result

        //储存公司地址gps
        if (this.firm_data.gps) {
          localStorage.setItem("firm_gps", JSON.stringify(this.firm_data.gps))
        }
        //储存公司地址
        if (this.firm_data.address) {
          localStorage.setItem("firm_address", this.firm_data.address)
        }

        //储存公司所属地区
        if (this.firm_data.street) {
          localStorage.setItem("firm_street", JSON.stringify(this.firm_data.street))
        }



        // this.firm_data.logo = "https://6870-hphronline-0gf0t91ufecc02db-1303180979.tcb.qcloud.la/company_image/companyLogo-image1610780570161-37.png"
        // this.firm_data.licenseImage = ['cloud://hphronline-0gf0t91ufecc02db.6870-hphronlin…le/business-licenseImage_one1609556709794-244.JPG']
      })
    },
  },

  computed: {
    menuitemClasses: function () {
      return [
        'menu-item',
        this.isCollapsed ? 'collapsed-menu' : ''
      ]
    }
  },
  created() {
    this.get_firm_data()
    console.log(this.CheckSocialCreditCode("91310120MACWP8UN51"));
  },
  mounted() {
    if (sessionStorage.getItem('Menu')) {
      this.title_name = sessionStorage.getItem('Menu')
    }
  },
}
</script>

<style lang="less" scoped>
@import "./Home.less";
</style>
